<template>
  <div class="autowarp">
    <h1>您的登录存在异常,请尝试重新登录</h1>
    <el-button @click="jump"
               type="success">重新登录</el-button>
  </div>
</template>

<script>
export default {
  name: "error_401",
  methods: {
    jump () {
    }
  }
};
</script>
<style lang="less">
.autowarp {
  text-align: center;
  h1 {
    padding: 150px 0;
    font-size: 40px;
  }
  .el-button {
    width: 180px;
    height: 40px !important;
    font-size: 24px;
  }
}
</style>